// TinyURLGenerator.js
import React, { useState } from 'react';
import { Box, Container, TextField, Button } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';


const TinyURLGenerator = () => {
  const [fullURL, setFullURL] = useState('');
  const [shortURL, setShortURL] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(`https://tinyurl.sujashaj.com/generateTinyUrl`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ "full_url": fullURL }),
      });

      const responseData = await response.json();
      if (response.ok) {
        console.log('Short URL generated successfully!');
        setShortURL(responseData.short_url);
      } else {
        console.error('Failed to generate short URL.');
      }
    } catch (error) {
      console.error('Error generating short URL:', error);
    }
  };

  const defaultTheme = createTheme();

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container maxWidth="sm">
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            px: 12,
            py: 6,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <TextField
            autoComplete="off"
            name="fullURL"
            required
            fullWidth
            id="fullURL"
            label="Enter Full URL"
            autoFocus
            // size="small"
            value={fullURL}
            onChange={(e) => setFullURL(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 2 }}
          >
            Generate Short URL
          </Button>
          {shortURL && (
            <TextField
              fullWidth
              id="shortURL"
              label="Shortened URL"
              value={shortURL}
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
              sx={{ mt: 5 }}
            />
          )}
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default TinyURLGenerator;

