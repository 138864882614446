import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import UrlInputBox from './components/UrlInputBox'; // Assuming correct file path
import AppHeader from './components/AppHeader';
import Submit from './components/Submit';
import TinyURLGenerator from './components/TinyUrlGenerator';

function App() {
  return (
    <BrowserRouter>
    <AppHeader/>
    <TinyURLGenerator/>
    
    
      
    </BrowserRouter>
  );
}

export default App;
