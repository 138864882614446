import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { createTheme, ThemeProvider } from '@mui/material/styles';


const defaultTheme = createTheme({
});

export default function Types() {
    return (
        
        <ThemeProvider theme={defaultTheme}>
          <AppBar position="static">
            <Toolbar variant="dense" 
            disableGutters sx={{ minHeight: 100, height: 100 }}
            >
              <Typography variant="h3" component="div" sx={{ display:"flex", justifyContent:"center", flexGrow: 100 }}>
                Tiny Url Application
              </Typography>
        
       
              
              
            </Toolbar>
          </AppBar>
          
        </ThemeProvider>
      );
    }

    
          